<template>
  <v-app-bar height="100px" color="white" fixed app style="z-index: 99">
    <v-container class="d-flex pa-0">
      <logo />
      <v-spacer />
      <button
        aria-label="sidebar"
        class="v-app-bar__nav-icon v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default hidden-md-and-up"
        @click.stop="drawer = !drawer"
      >
        <span class="v-btn__content">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            class="svg-inline--fa fa-bars fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style="height: 20px; width: 20px"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </span>
      </button>
      <div class="hidden-sm-and-down">
        <v-list dense nav flat rounded>
          <v-list-item-group color="primary" class="d-flex flex-row">
            <v-list-item class="mb-0" v-for="(item, id) in navbar" :key="id" :to="item.goTo">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-container>
    <v-navigation-drawer
      app
      v-model="drawer"
      absolute
      temporary
      right
      width="100%"
    >
      <template v-slot:prepend>
        <v-list-item
          three-line
          style="box-shadow: 0 0 10px rgba(140, 140, 140, 0.2);"
        >
          <logo />
          <v-spacer />
          <button
            aria-label="sidebar"
            type="button"
            class="v-app-bar__nav-icon v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default"
            @click.stop="drawer = !drawer"
          >
            <span class="v-btn__content">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4887 10.001L19.6917 1.79793C20.1028 1.38683 20.1028 0.72035 19.6917 0.309296C19.2806 -0.101797 18.6141 -0.101797 18.2031 0.309296L9.99998 8.51233L1.79695 0.309296C1.38586 -0.101797 0.719374 -0.101797 0.30832 0.309296C-0.102734 0.720389 -0.102773 1.38687 0.30832 1.79793L8.51135 10.001L0.30832 18.204C-0.102773 18.6151 -0.102773 19.2816 0.30832 19.6926C0.719413 20.1037 1.3859 20.1037 1.79695 19.6926L9.99998 11.4896L18.203 19.6926C18.6141 20.1037 19.2806 20.1037 19.6916 19.6926C20.1027 19.2815 20.1027 18.615 19.6916 18.204L11.4887 10.001Z"
                  fill="#333333"
                />
              </svg>
            </span>
          </button>
        </v-list-item>
      </template>
      <v-list nav dense flat>
        <div v-for="(item, id) in navbar" :key="id">
          <v-list-item :to="item.goTo" active-class="primary--text">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
      <template v-slot:append>
        <Footer />
      </template>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import Logo from "./Logo";
import Footer from "./Footer";
export default {
  name: "NavBar",
  components: { Footer, Logo },
  data() {
    return {
      drawer: false,
      group: null,
      navbar: [
        { title: "ホーム", goTo: "/" },
        { title: "建設現場のリモート業務支援 CSRSシステム", goTo: "/csrs" },
        {
          title: "リモート技術者支援サービス",
          goTo: "/remote-engineer-support-service"
        },
        { title: "会社メッセージ", goTo: "/company-msg" }
      ]
    };
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    navbarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "64px";
        case "sm":
          return "64px";
        case "md":
          return "100px";
        case "lg":
          return "100px";
        case "xl":
          return "100px";
      }
    }
  }
};
</script>

<style scoped></style>
