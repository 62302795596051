import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue")
  },
  {
    path: "/csrs",
    name: "CSRS",
    component: () =>
      import(/* webpackChunkName: "CSRS" */ "@/views/Services/CSRS.vue")
  },
  {
    path: "/remote-engineer-support-service",
    name: "RESS",
    component: () =>
      import(/* webpackChunkName: "RESS" */ "@/views/Services/RESS.vue")
  },
  {
    path: "/company-msg",
    name: "CompanyMsg",
    component: () =>
      import(/* webpackChunkName: "CompanyMsg" */ "@/views/CompanyMessage.vue")
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "Pricing" */ "@/views/PlansPricing.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
});

export default router;
