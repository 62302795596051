<template>
  <router-link to="/" class="logo">
    <span>現場のリモート業務支援</span>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  props: {
    color: {
      type: String,
      default: "#303030"
    }
  }
};
</script>

<style lang="sass" scoped>
.logo
  text-decoration: none
  color: black
  display: flex
  align-items: center
</style>
