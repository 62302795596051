<template>
  <v-app>
    <nav-bar />
    <v-content>
      <router-view />
    </v-content>
    <Footer />
  </v-app>
</template>

<script>
const NavBar = () => import(/* webpackMode: "eager" */ "@/components/NavBar");
const Footer = () => import(/* webpackMode: "eager" */ "@/components/Footer");

export default {
  name: "App",

  components: {
    Footer,
    NavBar
  },

  data: () => ({
    //
  }),

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
html, body
  overflow-x: hidden
h2
  font-weight: bold
  font-size: 30px
h3
  font-weight: bold
  font-size: 22px
p
  font-size: 15px
  font-weight: 500
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset
  color: #ffffff !important
.v-text-field>.v-input__control>.v-input__slot:before
  border-color: #ffffff !important
.v-input, .theme--light.v-input input, .theme--light.v-input textarea
  color: #ffffff !important
.v-label
  color: #ffffff !important
.font-18
  font-size: 18px
.mb-55
  margin-bottom: 55px !important
.Masthead
  z-index: 0 !important
@media #{map-get($display-breakpoints, 'md-and-up')}
  h2
    font-weight: bold
    font-size: 40px
  h3
    font-weight: bold
    font-size: 22px
  p
    font-size: 15px
</style>
