<template>
  <v-footer color="#fff" class="pa-0">
    <v-row>
      <v-col cols="12" class="text-center py-7">
        ©{{ current_year }} by JThink Co., Ltd.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      current_year: new Date().getFullYear()
    };
  }
};
</script>

<style scoped></style>
